// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-form-completed-js": () => import("./../../../src/components/Form/Completed.js" /* webpackChunkName: "component---src-components-form-completed-js" */),
  "component---src-components-form-form-js": () => import("./../../../src/components/Form/Form.js" /* webpackChunkName: "component---src-components-form-form-js" */),
  "component---src-components-form-mechanics-js": () => import("./../../../src/components/Form/Mechanics.js" /* webpackChunkName: "component---src-components-form-mechanics-js" */),
  "component---src-components-form-summary-js": () => import("./../../../src/components/Form/Summary.js" /* webpackChunkName: "component---src-components-form-summary-js" */),
  "component---src-components-static-pages-help-center-js": () => import("./../../../src/components/StaticPages/HelpCenter.js" /* webpackChunkName: "component---src-components-static-pages-help-center-js" */),
  "component---src-components-static-pages-privacy-policy-js": () => import("./../../../src/components/StaticPages/PrivacyPolicy.js" /* webpackChunkName: "component---src-components-static-pages-privacy-policy-js" */),
  "component---src-components-static-pages-terms-and-conditions-js": () => import("./../../../src/components/StaticPages/TermsAndConditions.js" /* webpackChunkName: "component---src-components-static-pages-terms-and-conditions-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-epharmacy-js": () => import("./../../../src/pages/epharmacy.js" /* webpackChunkName: "component---src-pages-epharmacy-js" */),
  "component---src-pages-financial-assessment-old-js": () => import("./../../../src/pages/financial-assessment-old.js" /* webpackChunkName: "component---src-pages-financial-assessment-old-js" */),
  "component---src-pages-giotrif-application-js": () => import("./../../../src/pages/giotrif/application.js" /* webpackChunkName: "component---src-pages-giotrif-application-js" */),
  "component---src-pages-giotrif-avail-js": () => import("./../../../src/pages/giotrif/avail.js" /* webpackChunkName: "component---src-pages-giotrif-avail-js" */),
  "component---src-pages-giotrif-enroll-js": () => import("./../../../src/pages/giotrif/enroll.js" /* webpackChunkName: "component---src-pages-giotrif-enroll-js" */),
  "component---src-pages-giotrif-js": () => import("./../../../src/pages/giotrif.js" /* webpackChunkName: "component---src-pages-giotrif-js" */),
  "component---src-pages-giotrif-medicine-availment-receipt-index-js": () => import("./../../../src/pages/giotrif/medicine-availment/receipt/index.js" /* webpackChunkName: "component---src-pages-giotrif-medicine-availment-receipt-index-js" */),
  "component---src-pages-giotrif-medicine-availment-receipt-upload-js": () => import("./../../../src/pages/giotrif/medicine-availment/receipt/upload.js" /* webpackChunkName: "component---src-pages-giotrif-medicine-availment-receipt-upload-js" */),
  "component---src-pages-giotrif-medicine-availment-upload-js": () => import("./../../../src/pages/giotrif/medicine-availment/upload.js" /* webpackChunkName: "component---src-pages-giotrif-medicine-availment-upload-js" */),
  "component---src-pages-help-center-js": () => import("./../../../src/pages/help-center.js" /* webpackChunkName: "component---src-pages-help-center-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-consent-form-js": () => import("./../../../src/pages/patient-consent-form.js" /* webpackChunkName: "component---src-pages-patient-consent-form-js" */),
  "component---src-pages-patient-old-js": () => import("./../../../src/pages/patient-old.js" /* webpackChunkName: "component---src-pages-patient-old-js" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-terms-and-conditions-mdx": () => import("./../../../src/pages/terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-terms-and-conditions-mdx" */)
}

